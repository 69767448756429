<template>
  <div class="grid grid4">
    <poll-card
      v-for="poll in polls"
      :key="poll.id"
      :poll="poll"
    />
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { getAll as getAllPolls } from '@/api/providers/poll';

import PollCard from '@/components/Poll/PollCard.vue';

export default {
  components: {
    PollCard
  },

  data() {
    return {
      polls: []
    };
  },

  beforeMount() {
    this.addAction({
        handler: () => this.$router.push('/polls/new'),
        icon: 'add',
        text: 'Nieuw',
      });
    this.getPolls();
  },

  methods: {
    ...mapActions('appHeader', [
      'addAction',
    ]),
    async getPolls() {
      this.polls = await getAllPolls(); 
    }
  }
}
</script>