<template>
  <div class="card">
    <router-link
      :to="`/polls/${poll.id}`"
    >
      <div class="card-body">
        <div class="content">
          <h6>
            {{ poll.question }}
          </h6>
          <div
            v-for="option in options"
            :key="option.id"
            class="option"
          >
            <p
              class="title mb-0"
              :title="option.anwser"
            >
              {{ option.anwser }}
            </p>
            <div
              class="d-flex align-items-center"
            >
              <span
                style="font-family: 'Roboto Mono'"
              >
                {{ option.votes.toString().padStart(3, '&nbsp;') }}
              </span>
              <div class="divider" />
              <div
              class="graph-bar"
              :style="getGraphBarSizeStyle(option.votes)"
              />
            </div>
          </div>

          <span
            class="votes"
          >
            Stemmen: {{ poll.totalVotesCount }}
          </span>

          <material-badge
            v-if="pollHasNotEnded"
            size="sm"
          >Actief</material-badge>
        </div>
      </div>
    </router-link>
  </div>
</template>

<script>
import MaterialBadge from '@/components/MaterialBadge.vue';

export default {
  components: {
    MaterialBadge
  },

  props: {
    poll: {
      type: Object,
      required: true
    }
  },

  computed: {
    options() {
      const pollOptions = this.poll.pollOptions;
      return pollOptions.sort((a, b) => a.votes < b.votes );
    },
    
    pollHasNotEnded() {
      return new Date(this.poll.endDate) > new Date();
    }
  },

  methods: {
    getGraphBarSizeStyle(optionVotes) {
      const barSize = (optionVotes / this.poll.totalVotesCount) * 100;
      
      return {
        width: `${barSize}%`
      };
    }
  }
}
</script>

<style scoped>
.option .title {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 0.8rem;
}

.option .divider { 
  width: 2px;
  height: 15px;
  background: #e91e63;
  margin-inline: 5px;
}

.graph-bar {
  height: 10px;
  background-color: #e91e63;
}

.votes, .badge {
  position: absolute;
  bottom: 20px;
}

.votes {
  left: 20px;
}

.badge {
  right: 20px;
}

.content {
  padding-bottom: 30px;
}
</style>